// eslint-disable-next-line import/prefer-default-export
const scrollToFirstError = errors => {
  const { firstInput, secondInput } = getErrorEntries(errors);
  const firstInputWithError = document.querySelector(`[name="${firstInput}"]`);
  const secondInputWithError = document.querySelector(
    `[name="${secondInput}"]`,
  );

  if (firstInputWithError || secondInputWithError) {
    const firstPosition = getPositionInputWithError(firstInputWithError);
    const secondPosition = getPositionInputWithError(secondInputWithError);
    const offset = 100;
    const position = Math.max(firstPosition, secondPosition);
    window.scrollTo({ top: position - offset, behavior: 'smooth' });
  }
};

const getPositionInputWithError = inputWithError => {
  return inputWithError
    ? inputWithError.getBoundingClientRect().top +
        document.documentElement.scrollTop
    : 0;
};

const getErrorEntries = errors => {
  const [
    [firstKey, firstValue] = [],
    [secondKey, secondValue] = [],
  ] = Object.entries(errors);
  const firstInput = extractInvalidInputName(firstKey, firstValue);
  const secondInput = extractInvalidInputName(secondKey, secondValue);
  return { firstInput, secondInput };
};

const extractInvalidInputName = (formKey, formValue) => {
  if (typeof formValue === 'string') {
    return formKey;
  }
  if (Array.isArray(formValue)) {
    return `${formKey}${getArrayError(formValue)}`;
  }
  if (typeof formValue === 'object') {
    return `${formKey}.${Object.keys(formValue)[0]}`;
  }
  return '';
};

const getArrayError = formValue => {
  const errorEntries = formValue
    .map((error, index) => ({ index, error }))
    .find(entry => entry.error !== undefined);
  const { index, error } = errorEntries;
  const firstInput =
    typeof error === 'object' ? Object.keys(error)[0] : undefined;
  const nestedError = getNestedError(error, firstInput);
  return nestedError
    ? `[${index}].${firstInput}.${nestedError}`
    : `[${index}].${firstInput}`;
};

const getNestedError = (error, firstError) => {
  return firstError &&
    error[firstError] &&
    typeof error[firstError] === 'object'
    ? Object.keys(error[firstError])[0]
    : undefined;
};

export { scrollToFirstError, getPositionInputWithError, getErrorEntries };
